<template>
  <Title pageTitle="FABRICS" pageDiscrption="生地・カラー" />

  <div class="container">
    <img class="indicator" src="../assets/img/chart_sample.png" alt="" />
    <div class="chart">
      <div class="chart__item" v-for="fabric in fabrics" :key="fabric">
        <div class="chart__item--heading">{{ fabric.title }}</div>
        <div class="chart__item--line"></div>
        <p>{{ fabric.discription }}</p>
        <img class="chart__item--position" :src="fabric.position" alt="" />
        <div :class="{ features: fabric.isFeatures }">
          {{ fabric.features }}
        </div>
        <p class="chart__item--spec">{{ fabric.spec }}</p>

        <swiper
          :slides-per-view="1"
          :space-between="50"
          navigation
          :pagination="{ clickable: true }"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
        >
          <swiper-slide><img :src="fabric.white" alt="white"/></swiper-slide>
          <swiper-slide><img :src="fabric.gray" alt="gray"/></swiper-slide>
          <swiper-slide><img :src="fabric.navy" alt="navy"/></swiper-slide>
          <swiper-slide><img :src="fabric.black" alt="black"/></swiper-slide>
          ...
        </swiper>
      </div>
    </div>
    <div class="note">
      <ul>
        <li>写真の生地色は閲覧する端末により色味が異なる場合があります。</li>
        <li>
          指標チャートの各ポジションは当店基準で定めておりますので、お客様によって印象が異なる場合があります。
        </li>
        <li>
          実際の生地をご覧になりたい方は「<router-link to="/contact"
            >生地見本付きリーフレット</router-link
          >」を送付させていただきます。
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import Title from '../components/Title.vue'

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

export default {
  components: { Title, Swiper, SwiperSlide },
  setup() {
    const preData = ref([])
    const fabrics = ref([])
    const error = ref(null)

    const load = async () => {
      try {
        let data = await fetch('./data/fabric.json')
        if (!data.ok) {
          throw Error('no data available')
        }
        preData.value = await data.json()
        fabrics.value = preData.value.fabrics
      } catch (err) {
        error.value = err.message
        console.log(error.value)
      }
    }

    load()

    const onSwiper = swiper => {
      // console.log(swiper)
    }
    const onSlideChange = () => {
      // console.log('slide change')
    }

    return { fabrics, onSwiper, onSlideChange }
  },
}
</script>

<style lang="scss" scoped>
.indicator {
  display: block;
  margin: 0 auto;
  width: 50%;
  margin-bottom: 5rem;

  @media screen and (max-width: 599px) {
    width: 100%;
  }
}
.chart {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 3rem;

  &__item {
    background-color: #333;
    padding: 5rem 2rem 2rem;

    &--heading {
      text-align: center;
      font-size: 2.3rem;
      font-weight: 300;
      letter-spacing: 0.2em;
    }

    &--line {
      width: 50px;
      height: 1px;
      background-color: #777;
      margin: 3rem auto;
    }

    &--position {
      display: block;
      width: 100%;
      margin: 15px 0;
    }

    &--spec {
      font-size: 80%;
    }
  }
}
.features {
  background-color: rgb(240, 226, 35);
  display: inline-block;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  padding: 1px 10px 2px;
  margin-bottom: 10px;
  border-radius: 5px;
}

/* note */
.note {
  margin-top: 3rem;

  & li {
    font-size: 13px;
    margin-left: 2rem;
    margin-bottom: 0.5rem;

    & a {
      font-size: 12px;
      border-bottom: 1px solid var(--color-medium);
    }
  }
}
</style>

<style>
/* Swiper */
.swiper-slide img {
  width: 100%;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 2.5rem;
  color: #999;
}
</style>
